<template>
  <div>
    <div class="row">
      <div class="col-12">
        <c-card title="기본정보" class="cardClassDetailInfo">
          <template slot="card-button">
            <c-appr-btn 
              ref="appr-btn"
              name="risk-assess-appr-btn"
              :editable="editable"
              :approvalInfo="approvalInfo"
              @beforeApprAction="saveDataAppr"
              @callbackApprAction="approvalCallback"
              @callbackEndEvent="approvalCallbackEndEvent"
              @requestAfterAction="getList"
            />
          </template>
          <template slot="card-detail">
            <div class="col-xs-12 col-sm-6 col-md-2">
              <c-label-text title="공사현장" :value="assessPlan.plantName"></c-label-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-5">
              <c-label-text title="평가명" :value="assessPlan.assessmentName"></c-label-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3">
              <c-label-text title="평가기간" :value="assessPlan.assessmentDate"></c-label-text>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-2">
              <c-label-text title="진행상태" :value="assessPlan.approvalStatusName && assessPlan.approvalStatusCd!=='ASC9999999'? 
              assessPlan.approvalStatusName : assessPlan.stepCustomName"></c-label-text>
            </div>
          </template>
        </c-card>
      </div>
    </div>
    <c-tab
      ref="actionTab"
      type="tabcard"
      align="left"
      :tabItems="tabItems"
      :inlineLabel="true"
      :height.sync="tabHeight"
      v-model="tab"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :height="height"
          :targetData.sync="tab.targetData"
          :assessPlan.sync="assessPlan"
          :planUpdateBtnData="param.planUpdateBtnData"
          :param.sync="param"
          @assessComplete="assessComplete"
          @research="research"
          @approveChange="approveChange"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'risk-assess-action',
  props: {
    param: {
      type: Object,
      default: () => ({
        riskAssessmentPlanId: '',
        stepCd: '',
        searchScenario: {
          flag: '',
          isSearch: false,
        },
        planUpdateBtnData: {
          title: '계획',
          flag: false,
          research: '',
          planResearch: '',
        },
      }),
    },
    height: {
      type: String,
      default: function() {
        return '';
      },
    },
  },
  data() {
    return {
      tab: '',
      tabItems: [],
      assessPlan: {
        riskAssessmentPlanId: '',
        plantCd: '',
        assessTypeCd: '',
        assessmentName: '',
        assessmentStartDate: '',
        assessmentEndDate: '',
        assessmentYear: '',
        stepCd: '',
        assessmentManageUserId: '',
        assessmentManageVendorCd: '',
        ramMatrixId: null,
        reviewDate: '',
        approvalUserId: '',
        approvalDate: '',
        sopMapId: '',
        remark: '',
        reviewUserId: '',
        approvalStatusCd:'',
        approvalTypeCd:'',
        approvalStatusName:'',
        sysApprovalRequestId:'',
      },
      isComplete: false,
      listUrl: '',
      completeUrl: '',
      isApproval: false,
      approval: true,
      editFlag: false,
    };
  },
  computed: {
    approvalInfo() {
      return {
        sysApprovalRequestId: this.assessPlan.sysApprovalRequestId, // 결재요청번호
        /**
         * 결재상태코드
         * ASC0000001	결재중
         * ASC0000002	반려
         * ASC9999999	결재완료
         */
        approvalStatusCd: this.assessPlan.approvalStatusCd, 
        apprEditable: this.param.stepCd !== 'RAS000010' && this.approval && !this.editFlag,
        param: this.assessPlan.vendorList, // 결재 param
        approvalUrl: this.completeUrl, // 결재 url
        isApproval: this.isApproval, // 결재 submit
        isEndFlag: this.param.stepCd !== 'RAS000010' && this.approval && !this.editFlag,
        endFlagBtnLabel: '전체 평가완료',
        approvalTypeCd: 'APTC000003', // 결재유형코드
        approvalParamValue: { // 결재상세화면 파라미터
          riskAssessmentPlanId: this.param.riskAssessmentPlanId,
          isApprContent: true
        },
        approvalRequestName: '위험성평가 [' + this.assessPlan.assessmentName + ']', // 결재요청명 (문서 title)
        approvalConnId: this.assessPlan.riskAssessmentPlanId, // 결재연결용 업무일련번호 (문서 key)
      }
    },
    completeFlag() {
      return this.tabItems 
        && this.tabItems.length > 0
        && this.$_.findIndex(this.tabItems, (item) => {
              return item.targetData.processAssessStepCd !== 'RPA0000005'
            }) === -1 
        && this.param.stepCd === 'RAS000010'
    },
    tabHeight() {
      let numHeight = this.height ? Number(this.$_.replace(this.height, 'px', '')) : 600;
      numHeight = numHeight - 140;
      return numHeight + 'px';
    },
    editable() {
      return this.$route.meta.editable && 
      (this.assessPlan.approvalUserId === this.$store.getters.user.userId
        || this.assessPlan.reviewUserId === this.$store.getters.user.userId
        || this.assessPlan.regUserId === this.$store.getters.user.userId)
    },
  },
  watch: {
    'param.searchScenario.flag'() {
      this.getList();
    },
    'param.planUpdateBtnData.research'() {
      this.getList();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      // url setting
      this.listUrl = selectConfig.saas.targetVendor.list.url;
      this.completeUrl = transactionConfig.saas.action.complete.url;
      // code setting

      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.$format(this.listUrl, this.param.riskAssessmentPlanId);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.assessPlan = this.$_.clone(_result.data)

        this.tabItems = [];
        if (_result.data.vendorList) {
          this.$_.forEach(_result.data.vendorList, item => {
            this.tabItems.push({
              key: uid(),
              name: item.riskAssessmentVendorId, 
              icon: 'work_outline', 
              label: item.assessVendorName, 
              targetData: item,
              component: () => import(`${'./riskAssessActionDetail.vue'}`)
            })
            if (item.processAssessStepCd === 'RS00000001') {
              this.approval = false;
            }
          })
          let idx = 0;
          if (this.$store.getters.user.innerFlag === 'N') {
            idx = this.$_.findIndex(_result.data.vendorList, {assessVendorId: this.$store.getters.user.deptCd})
          } else {
            idx = this.$_.findIndex(_result.data.vendorList, {assessVendorId: this.$store.getters.user.selfVendorCd})
          }
          if (idx === -1) {
            idx = 0;
          }
          this.tab = _result.data.vendorList[idx].riskAssessmentVendorId
        }
      },);
    },
    assessComplete(stepCd) {
      this.getList();
      // stepper 이동
      this.$emit('emitStep', {
        name: 'stepBystep',
        param: stepCd
      })
    },
    saveDataAppr() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '결재요청하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          // if (this.actionData.meetList) {
          //   this.$_.forEach(this.actionData.meetList, item => {
          //     item.regUserId = this.$store.getters.user.userId
          //     item.chgUserId = this.$store.getters.user.userId
          //     item.meetingStartTime = item.meetingTime[0]
          //     item.meetingEndTime = item.meetingTime[1]
          //   }) 
          // }
          let promises = [
            {
              func: this.approvalValue
            },
          ];
          this.$comm.orderedPromise(promises);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    // [S] 결재관련 
    approvalCallback() { // 결재관련 결재요청버튼 callback
      this.$refs['appr-btn'].apprRequestPop();
    },
    approvalValue() {
      return new Promise(resolve => {
        this.isApproval = !this.isApproval
        resolve(true);
      });
    },
    research(data) {
      let idx =this.$_.findIndex(this.assessPlan.vendorList, {riskAssessmentVendorId:data});
      this.assessPlan.vendorList[idx].processAssessStepCd = 'RS00000005';

      if (this.$_.findIndex(this.assessPlan.vendorList, {processAssessStepCd:'RS00000001'}) === -1) {
        this.approval = true;
      } else {
        this.approval = false;
      }
    },
    approveChange(flag) {
      this.editFlag = flag
    },
    approvalCallbackEndEvent() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '평가완료 처리하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.completeUrl + '/end';
          this.$http.type = 'PUT';
          this.$http.param = {
            riskAssessmentPlanId: this.param.riskAssessmentPlanId,
          }
          this.$http.request(() => {
            this.approval = false;
            this.assessComplete('RAS000010');
            window.getApp.$emit('ALERT', {
              title: '안내 ', /* 안내 */
              message: '평가완료 처리되었습니다.', /* 저장되었습니다. */
              type: 'success', // success / info / warning / error
            });
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    }
  }
};
</script>
