var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [
            _c(
              "c-card",
              {
                staticClass: "cardClassDetailInfo",
                attrs: { title: "기본정보" },
              },
              [
                _c(
                  "template",
                  { slot: "card-button" },
                  [
                    _c("c-appr-btn", {
                      ref: "appr-btn",
                      attrs: {
                        name: "risk-assess-appr-btn",
                        editable: _vm.editable,
                        approvalInfo: _vm.approvalInfo,
                      },
                      on: {
                        beforeApprAction: _vm.saveDataAppr,
                        callbackApprAction: _vm.approvalCallback,
                        callbackEndEvent: _vm.approvalCallbackEndEvent,
                        requestAfterAction: _vm.getList,
                      },
                    }),
                  ],
                  1
                ),
                _c("template", { slot: "card-detail" }, [
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-2" },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "공사현장",
                          value: _vm.assessPlan.plantName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-5" },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "평가명",
                          value: _vm.assessPlan.assessmentName,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-3" },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "평가기간",
                          value: _vm.assessPlan.assessmentDate,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-xs-12 col-sm-6 col-md-2" },
                    [
                      _c("c-label-text", {
                        attrs: {
                          title: "진행상태",
                          value:
                            _vm.assessPlan.approvalStatusName &&
                            _vm.assessPlan.approvalStatusCd !== "ASC9999999"
                              ? _vm.assessPlan.approvalStatusName
                              : _vm.assessPlan.stepCustomName,
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
      _c("c-tab", {
        ref: "actionTab",
        attrs: {
          type: "tabcard",
          align: "left",
          tabItems: _vm.tabItems,
          inlineLabel: true,
          height: _vm.tabHeight,
        },
        on: {
          "update:height": function ($event) {
            _vm.tabHeight = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  key: tab.key,
                  tag: "component",
                  attrs: {
                    height: _vm.height,
                    targetData: tab.targetData,
                    assessPlan: _vm.assessPlan,
                    planUpdateBtnData: _vm.param.planUpdateBtnData,
                    param: _vm.param,
                  },
                  on: {
                    "update:targetData": function ($event) {
                      return _vm.$set(tab, "targetData", $event)
                    },
                    "update:target-data": function ($event) {
                      return _vm.$set(tab, "targetData", $event)
                    },
                    "update:assessPlan": function ($event) {
                      _vm.assessPlan = $event
                    },
                    "update:assess-plan": function ($event) {
                      _vm.assessPlan = $event
                    },
                    "update:param": function ($event) {
                      _vm.param = $event
                    },
                    assessComplete: _vm.assessComplete,
                    research: _vm.research,
                    approveChange: _vm.approveChange,
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }